var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M6.95498 1.39798L8.14798 6.68098C10.675 4.81998 13.789 3.70998 17.169 3.70998C25.605 3.70998 32.444 10.55 32.444 18.985C32.444 21.589 31.79 24.036 30.641 26.18C30.723 25.631 30.78 25.075 30.78 24.502C30.78 18.285 25.741 13.246 19.524 13.246C17.163 13.246 14.989 13.994 13.18 15.238L16.961 18.73L16.383 20.888L0.552979 16.646L4.79298 0.815979L6.95498 1.39798Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }