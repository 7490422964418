var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "businesses-fav" }, [
    _c(
      "section",
      {
        staticClass: "border-bottom bg-lighter md-up:sticky-top",
        staticStyle: { top: "70px" }
      },
      [
        _c("div", { staticClass: "container py-3" }, [
          _c("nav", { staticClass: "nav sm-down:flex-column" }, [
            _c(
              "span",
              {
                staticClass:
                  "btn btn-light text-left d-block sm-down:w-fill mr-auto",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "Businesses" })
                  }
                }
              },
              [
                _c(
                  "IconBase",
                  {
                    staticStyle: { "margin-right": "0" },
                    attrs: {
                      height: "13.632",
                      width: "14",
                      viewBox: "0 0 33 28"
                    }
                  },
                  [_c("BackArrow")],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(_vm.$t("back")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "btn btn-light" }, [
              _vm._v(
                "\n          Порядок, в котором отображаются бизнесы в разделе “Интересное” в\n          приложении.\n        "
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "container py-5 text-center" },
      [
        _vm.listFav.length === 0
          ? _c(
              "div",
              { staticClass: "border text-center p-3 mx-10 text-muted" },
              [_vm._v("\n      " + _vm._s(_vm.$t("no_fav")) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "row",
            attrs: {
              animation: 200,
              "scroll-sensitivity": 1000,
              list: _vm.listFav,
              group: "business"
            },
            on: { change: _vm.changeOrder }
          },
          _vm._l(_vm.listFav, function(arr, i) {
            return _c(
              "div",
              {
                key: i,
                class: [
                  i > 4
                    ? "xs:col-6 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                    : "col-12 mb-2 w-100 d-flex justify-content-center align-items-center"
                ]
              },
              [
                i < 5
                  ? _c(
                      "span",
                      {
                        staticClass: "btn btn-success mr-3",
                        staticStyle: { cursor: "grab" }
                      },
                      [_vm._v("\n          №" + _vm._s(i + 1) + "\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "mega-card",
                  {
                    staticClass: "rounded h-100 mb-0",
                    class: i > 4 ? "" : "w-50",
                    staticStyle: { cursor: "grab", "text-align": "center" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "navbar rounded",
                        class: i > 4 ? "bg-white" : "bg-primary"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 navbar-label mr-auto text-dark py-3 d-flex flex-row justify-content-center",
                            staticStyle: { "align-items": "center" }
                          },
                          [
                            _c("mega-image", {
                              staticClass: "rounded-full navbar-avatar mr-3",
                              staticStyle: { overflow: "hidden" },
                              attrs: { ratio: "1x1", small: "", src: arr.logo }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "content",
                                style: i > 4 ? "" : "color: white"
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "strong text-overflow" },
                                  [_vm._v(_vm._s(arr.name) + " ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted text-overflow" },
                                  [_vm._v(_vm._s("@arr_" + arr.idt_arp))]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "container pb-5 text-center" },
      [
        _c(
          "draggable",
          {
            staticClass: "row",
            attrs: {
              animation: 200,
              "scroll-sensitivity": 1000,
              list: _vm.list,
              group: "business"
            }
          },
          _vm._l(_vm.list, function(arr, i) {
            return _c(
              "div",
              {
                key: i,
                class: ["xs:col-6 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"]
              },
              [
                _c(
                  "mega-card",
                  {
                    staticClass: "rounded h-100 mb-0",
                    staticStyle: { cursor: "grab", "text-align": "center" }
                  },
                  [
                    _c("div", { staticClass: "navbar rounded bg-white" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 navbar-label mr-auto text-dark py-3 d-flex flex-row justify-content-center",
                          staticStyle: { "align-items": "center" }
                        },
                        [
                          _c("mega-image", {
                            staticClass: "rounded-full navbar-avatar mr-3",
                            staticStyle: { overflow: "hidden" },
                            attrs: { ratio: "1x1", small: "", src: arr.logo }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _c(
                              "small",
                              { staticClass: "strong text-overflow" },
                              [_vm._v(_vm._s(arr.name) + " ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "small",
                              { staticClass: "text-muted text-overflow" },
                              [_vm._v(_vm._s("@arr_" + arr.idt_arp))]
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }