<template>
  <main class="businesses-fav">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            @click="$router.push({ name: 'Businesses' })"
            class="btn btn-light text-left d-block sm-down:w-fill mr-auto"
          >
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 33 28'"
              style="margin-right: 0"
              ><BackArrow
            /></IconBase>
            <span class="ml-1">{{ $t("back") }}</span>
          </span>
          <span class="btn btn-light">
            Порядок, в котором отображаются бизнесы в разделе “Интересное” в
            приложении.
          </span>
        </nav>
      </div>
    </section>

    <section class="container py-5 text-center">
      <!-- <span class="h3 mb-8">  </span> -->
      <div
        v-if="listFav.length === 0"
        class="border text-center p-3 mx-10 text-muted"
      >
        {{ $t("no_fav") }}
      </div>
      <draggable
        class="row"
        :animation="200"
        @change="changeOrder"
        :scroll-sensitivity="1000"
        :list="listFav"
        group="business"
      >
        <div
          v-for="(arr, i) in listFav"
          :key="i"
          :class="[
            i > 4
              ? 'xs:col-6 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5'
              : 'col-12 mb-2 w-100 d-flex justify-content-center align-items-center'
          ]"
        >
          <span class="btn btn-success mr-3" style="cursor: grab" v-if="i < 5">
            №{{ i + 1 }}
          </span>
          <mega-card
            class="rounded h-100 mb-0"
            :class="i > 4 ? '' : 'w-50'"
            style="cursor: grab; text-align: center;"
          >
            <div
              class="navbar rounded"
              :class="i > 4 ? 'bg-white' : 'bg-primary'"
            >
              <div
                class="w-100 navbar-label mr-auto text-dark py-3 d-flex flex-row justify-content-center"
                style="align-items: center"
              >
                <mega-image
                  style="overflow:hidden"
                  class="rounded-full navbar-avatar mr-3"
                  ratio="1x1"
                  small
                  :src="arr.logo"
                />

                <div class="content" :style="i > 4 ? '' : 'color: white'">
                  <small class="strong text-overflow">{{ arr.name }} </small>
                  <small class="text-muted text-overflow">{{
                    "@arr_" + arr.idt_arp
                  }}</small>
                </div>
              </div>
            </div>
          </mega-card>
        </div>
      </draggable>
    </section>

    <section class="container pb-5 text-center">
      <draggable
        class="row"
        :animation="200"
        :scroll-sensitivity="1000"
        :list="list"
        group="business"
      >
        <div
          v-for="(arr, i) in list"
          :key="i"
          :class="['xs:col-6 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5']"
        >
          <mega-card
            class="rounded h-100 mb-0"
            style="cursor: grab; text-align: center;"
          >
            <div class="navbar rounded bg-white">
              <div
                class="w-100 navbar-label mr-auto text-dark py-3 d-flex flex-row justify-content-center"
                style="align-items: center"
              >
                <mega-image
                  style="overflow:hidden"
                  class="rounded-full navbar-avatar mr-3"
                  ratio="1x1"
                  small
                  :src="arr.logo"
                />

                <div class="content">
                  <small class="strong text-overflow">{{ arr.name }} </small>
                  <small class="text-muted text-overflow">{{
                    "@arr_" + arr.idt_arp
                  }}</small>
                </div>
              </div>
            </div>
          </mega-card>
        </div>
      </draggable>
    </section>
  </main>
</template>

<script>
import IconBase from "../../../components/icons/IconBase.vue";
import BackArrow from "../../../components/icons/set/BackArrow.vue";
import draggable from "vuedraggable";

export default {
  name: "Businesses_fav",
  components: {
    IconBase,
    BackArrow,
    draggable
  },
  data() {
    return {
      list: [],
      listFav: [],
      length: 0
    };
  },
  computed: {
    arp_ids() {
      let result = [];
      for (let i = 0; i < this.listFav.length; i++) {
        result.push(this.listFav[i]["idt_arp"]);
      }
      return result;
    }
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    changeOrder() {
      if (this.listFav.length > 5) {
        let el = this.listFav.pop();
        this.list.unshift(el);
      }
      let params = {
        arp_ids: this.arp_ids
      };
      this.$api.v2base.put("arp/promoted", params).then(() => {
        this.$alert.success("Succesfuly changed");
      });
    },
    loadPage($state) {
      this.loaded = false;

      let params = {
        limit: 60
      };

      this.$api.v2base
        .get("/arp/promoted")
        .then(({ data }) => {
          this.length = data.length;
          this.$navbar.name = `${this.$t("businesses")} (${this.length})`;

          if (data.length) {
            this.listFav.push(...data);
          }
        })
        .then(() => {
          this.$api.v2
            .get("/arps", { params })
            .then(({ data }) => {
              this.length += data.count;
              this.$navbar.name = `${this.$t("businesses")} (${this.length})`;

              if (data["arps"].length) {
                setTimeout(() => {
                  if ($state) $state.loaded();
                }, 500);
                let other_businesses = data["arps"].filter(item => {
                  return !this.listFav.some(
                    list_item => list_item["idt_arp"] == item["idt_arp"]
                  );
                });
                this.$root.list.push(...other_businesses);
                this.list.push(...other_businesses);
              } else $state.complete();
            })
            .catch(() => {
              this.$alert.danger("Oops.. Server error");
            });
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  }
};
</script>
